import {
    useEffect,
    useState
} from 'react'

function calculateHeight() {
    let height = window.innerHeight;
    const navbar = document.getElementById("navbar");
    if (navbar)
        height -= navbar.offsetHeight;
    return height;
}

export default function PrettyHeader(props) {
    useEffect(() => {
        function handleResize() {
            setHeight(calculateHeight());
        }
        window.addEventListener('resize', handleResize);
        handleResize();
    })
    const [height, setHeight] = useState(calculateHeight());

    const image = props.image;

    let style = {
        with: "100%",
        height: height+"px",
        position: "relative"
    };

    if (image) {
        style = {
            ...style,
            textAlign: "center",
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
        }
    }

    return <div style={style}>
        <div className="pretty-header-wrapper" style={{positoin: "absolute"}}>
            {props.children}
        </div>
    </div>



}