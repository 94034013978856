import {
    Link
} from 'react-router-dom';
import home from '../images/home.jpg'
import PrettyHeader from '../components/PrettyHeader'


export default function Home() {
    return <div>
        <PrettyHeader image={home}>
            <div style={{width: "50%", fontSize: "20px", padding: "20px"}}>
<b>Zwiedzanie Gdańska, Sopotu i Gdyni z przewodnikiem</b> to doskonały pomysł na wartościowe spędzenie czasu w Trójmieście.
Bez względu na porę roku, spacer po najpiękniejszych i najciekawszych zakątkach tych miast będzie miłym wspomnieniem
pobytu na polskim wybrzeżu.
Trójmiasto posiada różne oblicza, dlatego warto wybrać się zarówno na wycieczkę ogólnopoznawczą, jak i tematyczną.
Propozycje spacerów tematycznych znajdą Państwo w zakładce <Link to="/offer">Oferta.</Link>
            </div>
        </PrettyHeader>

    </div>
}