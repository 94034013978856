import {
    Link
} from 'react-router-dom';
import offer from '../images/offer.jpg'
import PrettyHeader from '../components/PrettyHeader'
import Page from '../components/Page';

export default function Offer() {
    return <div>
            <PrettyHeader image={offer}>
            <div style={{float: "right", padding: "30px", textAlign: "right"}}>
                <h1>Oferta</h1>
                <Link to="/offer/kingsroad">Droga Królewska</Link><br/>
                <Link to="/offer/oldtown">Stare Miasto</Link><br/>
                <Link to="/offer/westerplatte">Westerplatte</Link><br/>
                <Link to="/offer/sopot">Sopot</Link><br/>
                <Link to="/offer/gdynia">Gdynia</Link><br/>
                <Link to="/offer/other">Pozostałe propozycje tras</Link>

            </div>
        </PrettyHeader>
        <Page>
            <div style={{textAlign: "justify", padding: "20px"}}>
            <p>
            • Mam doświadczenie w&nbsp;oprowadzaniu grup o różnej liczebności, turystów indywidualnych, dzieci, młodzieży, dorosłych, seniorów oraz grup z niepełnosprawnościami.
            </p><p>
            • Dostosowuję narrację oraz tempo zwiedzania do wymagań i&nbsp;możliwości grupy.
            </p><p>
            • Program zwiedzania można wybrać z zakładki Przykładowe trasy zwiedzania lub mogę go skonstruować wedle indywidualnych potrzeb i&nbsp;życzeń grupy (w zależności od skomplikowania ta opcja może być dodatkowo płatna).
            </p><p>
            • Po szczegółowe informacje oraz aktualne ceny zapraszam do <Link to="/contact">kontaktu</Link>
            </p>
            </div>
        </Page>
    </div>
}