import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  React
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import {
  Navbar,
  Nav,
  Container,
 } from 'react-bootstrap';

import Home from './pages/Home'
import About from './pages/About'
import Offer from './pages/Offer'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'

import KingsRoad from './pages/toures/KingsRoad'
import OldTown from './pages/toures/OldTown'
import Westerplatte from './pages/toures/Westerplatte'
import Sopot from './pages/toures/Sopot'
import Gdynia from './pages/toures/Gdynia'
import Other from './pages/toures/Other'

function App(props) {
  return (
    <div className="App">
      <Navbar id="navbar" style={{position: "sticky"}} bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="/home">Spacery po Gdańsku</Navbar.Brand>
          <Navbar.Collapse>
            <Nav>
              <Nav.Link href="/about">O mnie</Nav.Link>

              <Nav.Link href="/offer">Oferta</Nav.Link>
              <Nav.Link href="/contact">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/home" element={<Home />}/>
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/offer" element={<Offer />}/>
          <Route exact path="/contact" element={<Contact />}/>
          <Route exact path="/offer/kingsroad" element={<KingsRoad />}/>
          <Route exact path="/offer/oldtown" element={<OldTown />}/>
          <Route exact path="/offer/westerplatte" element={<Westerplatte />}/>
          <Route exact path="/offer/sopot" element={<Sopot />}/>
          <Route exact path="/offer/gdynia" element={<Gdynia />}/>
          <Route exact path="/offer/other" element={<Other/>}/>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>


    </div>
  );
}

export default App;
