import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

export default function Page(props) {
    return <Container fluid="md" style={props.style} className="page-content">
        <Row>
            <Col>
                {props.children}
            </Col>
        </Row>
    </Container>
};