import contact from '../images/contact.jpg'
import facebook from '../images/facebook.png'
import PrettyHeader from '../components/PrettyHeader'

export default function Contact() {
    return <div>
        <PrettyHeader image={contact}>
            <div style={{width: "50%", fontSize: "20px", padding: "20px"}}>
                <p>Anna Piotrowska Przewodnik PTTK po Trójmieście</p>
                <p>Tel/WhatsApp: 577495618</p>
                <p>Mail: ania.m.piotrowska@gmail.com</p>
                <a href="https://www.facebook.com/TwojPrzewodnikPoTrojmiescie"><img width="64" src={facebook} alt="Facebook"/></a>
            </div>
        </PrettyHeader>
    </div>
}