import Page from "../../components/Page"
import sopot1 from "../../images/sopot/sopot_1.jpg"
import sopot2 from "../../images/sopot/sopot_2.jpg"
import sopot3 from "../../images/sopot/sopot_3.jpg"
import sopot4 from "../../images/sopot/sopot_4.jpg"
import sopot5 from "../../images/sopot/sopot_5.jpg"
import sopot6 from "../../images/sopot/sopot_6.jpg"
import sopot7 from "../../images/sopot/sopot_7.jpg"
import sopot8 from "../../images/sopot/sopot_8.jpg"

export default function Sopot(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>
            <h1>Sopot</h1>
            <p>
                Serdecznie zachęcam do spaceru z przewodnikiem po pięknym kurorcie jakim jest Sopot.<br/>
                Historia miejscowości ma ponad tysiąc lat, ale Sopot ma status miasta dopiero od początku XX wieku.
                Jego intensywny rozwój rozpoczął się w&nbsp;XIX wieku. Wtedy stał się popularnym kurortem.
                Ten wypoczynkowy i&nbsp;uzdrowiskowy charakter utrzymał do dziś.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot1} width="66%" alt=""/>
            </div>

            <h2>“Monciak”</h2>
            <p>
                Zwiedzanie Sopotu rozpocząć trzeba od najsłynniejszego deptaka w&nbsp;północnej
                Polsce - “Monciaka” - czyli ulicy Bohaterów Monte Cassino.
                To jedna z najstarszych i&nbsp;głównych ulic miasta.
                Na niej zobaczymy charakterystyczną sylwetkę neogotyckiego kościoła św. Jerzego,
                dominującą nad górną częścią deptaka. Idąc w&nbsp;dół miniemy dwa osobliwe pomniki:
                Misia Wojtka i&nbsp;sopockiego Parasolnika. U zbiegu Monciaka i&nbsp;ul. Haffnera
                znajduje się natomiast Krzywy Domek - niezwykła budowla inspirowana światem ilustracji i&nbsp;bajek,
                która otrzymała wiele architektonicznych wyróżnień.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot2} width="50%" alt=""/>
            </div>

            <h2>Okolice „Monciaka” i&nbsp;mola</h2>
            <p>
                Wychodząc z Monciaka trafiamy na przestronny Plac Przyjaciół Sopotu.
                Góruje nad nim sylwetka Domu Zdrojowego i&nbsp;Państwowej Galerii Sztuki – jednej z najlepszych galerii w&nbsp;Polsce!
                Nieopodal znajduje się piękny budynek, niczym z bajki.
                To Zakład Balneologiczny - świadek uzdrowiskowej historii Sopotu.
                Na jego dziedzińcu można skosztować sopockiej solanki, która jest również chętnie
                używana do kiszenia ogórków „po sopocku”.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot3} width="33%" alt=""/>
                <img src={sopot4} width="33%" alt=""/>
            </div>
            <div style={{textAlign: "center"}}>
                <img src={sopot5} width="50%" alt=""/>
            </div>

            <h2>Molo w&nbsp;Sopocie</h2>
            <p>
                Sopockie molo to kultowe miejsce, które jest obowiązkowym punktem do odwiedzenia podczas wycieczki po Sopocie.
                Spacer po tym najdłuższym drewnianym molo w&nbsp;Europie to doskonała okazja do relaksu.
                Na końcu pomostu znajduje się restauracja oraz marina jachtowa.
                Z tego miejsca rozciąga się wspaniała panorama Sopotu.
                Dostrzec można charakterystyczne miejsca: Łazienki Południowe, Park Południowy i&nbsp;Północny oraz potężną bryłę Grand Hotelu.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot6} width="50%" alt=""/>
            </div>

            <p>
                Ma on blisko stuletnią historię, był świadkiem działań wojennych i&nbsp;gościł
                wiele znanych osobistości m.in.  Marlene Dietrich, Charles de Gaulle,
                Shakira czy The Weeknd.
                Do dzisiaj jest popularnym i&nbsp;luksusowym miejscem a&nbsp;także planem filmowym dla polskich produkcji.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot7} width="50%" alt=""/>
            </div>
            <p>

                Sopot to obowiązkowe miejsce podczas wizyty w&nbsp;Trójmieście a&nbsp;najlepiej zwiedzać go jadąc z Gdańska do Gdyni.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={sopot8} width="66%" alt=""/>
            </div>
</Page>
</>
}