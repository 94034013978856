
import about1 from '../images/about1.jpg'
import about2 from '../images/about2.jpg'
import PrettyHeader from '../components/PrettyHeader'
import Page from '../components/Page';

export default function About() {
    return <div>
        <PrettyHeader image={about1}>
            <div className="image-font" style={{fontSize: "3vw", width: "50%", bottom: 0, position: "absolute"}}>
                <q>Wybierz pracę, którą kochasz i&nbsp;nie przepracujesz ani jednego dnia więcej w&nbsp;Twoim życiu.</q>
                <br/>
                <p style={{float: "right"}}>Konfucjusz</p>
            </div>
        </PrettyHeader>

        <Page style={{textAlign: "justify", padding: "20px"}}>
            <h1 style={{textAlign: "center"}}>O mnie</h1>
            <img src={about2} alt="" style={{float: "left"}} width="33%"/>
            <p>
                Z wykształcenia jestem historykiem sztuki. Od <b>2014</b> roku jestem <b> licencjonowanym przewodnikiem PTTK po Trójmieście. </b>
                Posiadam również <b> licencję na oprowadzanie po Hevelianum</b> w&nbsp;Gdańsku.
                w&nbsp;2018 roku ukończyłam <b>gedanistykę</b> - podyplomowe studia na Uniwersytecie Gdańskim.
            </p>
            <p>
                Opowiadanie turystom o Gdańsku, Sopocie i&nbsp;Gdyni jest pasją, która sprawia mi ogromną satysfakcję.
                Nieustannie poszerzam swoje horyzonty uczestnicząc w&nbsp;różnego rodzaju wykładach, szkoleniach i&nbsp;kursach.
                Sama również szkolę przyszłych przewodników z dziedziny sztuki.
            </p>
            <p>
                Staram się przekazać turystom <b>rzetelną wiedzę </b> na temat tych trzech niezwykłych miast poprzez ciekawą narrację
                wzbogaconą wyjątkowymi historiami, a&nbsp;czasem legendami. Opowiadam zarówno o przeszłości, jak i&nbsp;teraźniejszości.
            </p>
            <p>
                Oprowadzam grupy zorganizowane, grupy z niepełnosprawnościami oraz turystów indywidualnych. Wiek nie gra roli :)
            </p>
            <p>
                Zgodnie ze spostrzeżeniem Konfucjusza nie pracuję, ponieważ <b>bycie przewodnikiem to moja miłość</b>! Dlatego też nieustannie
                oprowadzam z uśmiechem na twarzy. :)
            </p>
            <p>
                w&nbsp;wolnych chwilach maluję oraz odwiedzam nieznane zakątki Trójmiasta i&nbsp;Pomorza.
            </p>
            <p>
                Moje szczególnie ulubione miejsca to <b>Muzeum Narodowe w&nbsp;Gdańsku, Westerplatte </b> oraz dawny polski statek szkolny - <b>Dar Pomorza w&nbsp;Gdyni</b>. 
            </p>
            <p>
                Jeśli są Państwo zainteresowani <b> jakie są zabytki na Drodze Królewskiej</b> w&nbsp;Gdańsku, <b>jakie muzea warto odwiedzić </b> w&nbsp;Trójmieście,
                <b> gdzie można dobrze zjeść </b> w&nbsp;Gdańsku, Gdyni i&nbsp;w Sopocie, to spacer z przewodnikiem będzie idealnym rozwiązaniem.
                Z wielką chęcią wskażę miejsca godne uwagi.
            </p>
            <p>
                Serdecznie zapraszam na spacer z przewodnikiem po Trójmieście! O każdej porze roku :)
            </p>

        </Page>
    </div>
}