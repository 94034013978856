import gdansk1 from "../../images/gdansk/gdansk_1.jpg"
import gdansk2 from "../../images/gdansk/gdansk_2.jpg"
import gdansk3 from "../../images/gdansk/gdansk_3.jpg"
import gdansk4 from "../../images/gdansk/gdansk_4.jpg"
import gdansk5a from "../../images/gdansk/gdansk_5a.jpg"
import gdansk5b from "../../images/gdansk/gdansk_5b.jpg"
import gdansk6 from "../../images/gdansk/gdansk_6.jpg"
import gdansk7 from "../../images/gdansk/gdansk_7.jpg"
import gdansk8 from "../../images/gdansk/gdansk_8.jpg"
import gdansk9 from "../../images/gdansk/gdansk_9.jpg"
import gdansk10 from "../../images/gdansk/gdansk_10.jpg"
import gdansk11 from "../../images/gdansk/gdansk_11.jpg"
import gdansk12 from "../../images/gdansk/gdansk_12.jpg"
import gdansk13 from "../../images/gdansk/gdansk_13.jpg"
import gdansk14 from "../../images/gdansk/gdansk_14.jpg"
import Page from "../../components/Page"

export default function KingsRoad(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>

            <h1>Droga Królewska</h1>
            <p>
                Zapraszam na <b>spacer z przewodnikiem</b> po najpiękniejszym gdańskim trakcie.
                To doskonała okazja, aby zobaczyć najważniejsze i&nbsp;najciekawsze <b>zabytki w&nbsp;Gdańsku</b>.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={gdansk1} width="66%" alt=""/>
            </div>

            <h2>Brama Wyżynna</h2>
            <p>
                Trasa wiedzie od Bramy Wyżynnej, przez którą wkraczali do miasta polscy królowie.
                Na pamiątkę ich uroczystych wizyt w&nbsp;Gdańsku nazwano ten szlak Drogą Królewską.
                Dalej można zobaczyć pozostałość po średniowiecznych fortyfikacjach w&nbsp;postaci
                okazałego Przedbramia ulicy Długiej, połączonego niegdyś pomostem z manierystyczną dzisiaj Złotą Bramą.
                Jest to ostatnia z ciągu bram, od której rozpoczyna się właściwa Droga Królewska.
            </p>
            <img src={gdansk2} width="50%" alt=""/>
            <img src={gdansk3} width="50%" alt=""/>

            <h2>Słynna ulica Długa i&nbsp;Długi Targ</h2>
            <p>
                Po przejściu przez Złotą Bramę wchodzimy na ulicę Długą, na której ukazuje się ciąg malowniczych kamienic.
                Patrząc na nie można się dowiedzieć <b>jak odbudowano Gdańsk po II wojnie światowej</b>,
                oraz podziwiać różne <b>style architektoniczne w&nbsp;Gdańsku</b>.
                w&nbsp;jednej z kamienic po prawej stronie mijamy Dom Uphagena.
                Dzisiaj jest to ciekawe Muzeum Wnętrz Mieszczańskich.
                To doskonałe miejsce, kiedy zastanawiamy się <b>jakie muzeum warto zwiedzić w&nbsp;Gdańsku</b>.
                Ten przepiękny krajobraz zwieńczony jest średniowieczną wieżą Ratusza Głównego Miasta,
                na której błyszczy złota figura Zygmunta Augusta, polskiego władcy.
                Zabytek ten przypomina, że znajdujemy się na <b>Drodze Królewskiej</b>.
            </p>
            <img src={gdansk4} width="33%" alt=""/>
            <img src={gdansk5a} width="33%" alt=""/>
            <img src={gdansk5b} width="33%" alt=""/>

            <h2>Bazylika Mariacka i&nbsp;Fontanna Neptuna</h2>
            <p>
                w&nbsp;sąsiedztwie Ratusza znajdują się pozostałe <b>najważniejsze zabytki w&nbsp;Gdańsku: bazylika Mariacka,
                Dwór Artusa</b> oraz słynna <b>fontanna Neptuna</b>. Zdobi ona szerszą część Drogi Królewskiej, nazwaną Długim Targiem.
                Tutaj, jak podpowiada nazwa, były zawierane transakcje handlowe i&nbsp;tętniło życie kupieckiego miasta.
                Odbywały się tu również różne uroczystości, powitania polskich królów i&nbsp;innych ważnych gości.
                Miały również miejsce wyroki śmierci.
            </p>
            <img src={gdansk6} width="33%" alt=""/>
            <img src={gdansk7} width="33%" alt=""/>
            <img src={gdansk8} width="33%" alt=""/>
            <div style={{textAlign: "center"}}>
                <img src={gdansk9} width="50%" alt=""/>
            </div>

            <h2>Dawny port Gdańska</h2>
            <p>
                Kiedy pójdziemy dalej dotrzemy do okazałej wodnej <b>Bramy Zielonej</b>, w&nbsp;której dzisiaj znajduje
                się <b>Muzeum Narodowe</b>.
                w&nbsp;czasach średniowiecza nazywana była Bramą Kogi, ponieważ cumowały przy niej statki tego typu.
                Za bramą możemy wejść na <b>Zielony Most</b> aby zobaczyć piękną panoramę <b>Długiego Pobrzeża</b>,
                biegnącego wzdłuż rzeki <b>Motławy</b>. To tutaj koncentrował się przez wieki <b>główny port w&nbsp;Gdańsku</b>.
                Podziwiając ten widok dostrzec można kolejne znane zabytki w&nbsp;Gdańsku: dźwig portowy zwany <b>Żurawiem</b>,
                rudowęglowiec <b>Sołdek</b> czy pozostałości muru <b>zamku krzyżackiego</b>.
                Zaskakuje również zupełnie nowa, ale nawiązująca do przedwojennej architektury zabudowa <b>Wyspy Spichrzów</b>.
            </p>
            <img src={gdansk10} width="50%" alt=""/>
            <img src={gdansk11} width="50%" alt=""/>
            <img src={gdansk12} width="50%" alt=""/>
            <img src={gdansk13} width="50%" alt=""/>

            <h2>Kierunek Żuławy</h2>
            <p>
                <b>Droga Królewska</b> prowadzi dalej w&nbsp;kierunku wschodnim ku kolejnym zachowanym przejściom:
                średniowiecznej Bramie Stągiewnej i&nbsp;Bramie Nowych Ogrodów, które prowadziły niegdyś w&nbsp;kierunku Żuław.
                Aby poznać bliżej dzieje i&nbsp;sekrety tej pięknej trasy, najlepiej wybrać się na <b>spacer
                z przewodnikiem po Gdańsku</b>.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdansk14} width="50%" alt=""/>
            </div>
        </Page>
    </>
}