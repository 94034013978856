import Page from "../../components/Page"
import gdynia1 from "../../images/gdynia/gdynia_1.jpg"
import gdynia2 from "../../images/gdynia/gdynia_2.jpg"
import gdynia3 from "../../images/gdynia/gdynia_3.jpg"
import gdynia4 from "../../images/gdynia/gdynia_4.jpg"
import gdynia5 from "../../images/gdynia/gdynia_5.jpg"
import gdynia6 from "../../images/gdynia/gdynia_6.jpg"
import gdynia7 from "../../images/gdynia/gdynia_7.jpg"
import gdynia8 from "../../images/gdynia/gdynia_8.jpg"

export default function Gdynia(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>

            <h1>Gdynia</h1>

            <p>
                Gdynia, podobnie jak Sopot, to miejscowość o niespełna stuletniej miejskiej historii.
                Prawa miejskie otrzymała 10. lutego 1926 roku. Wcześniej, od czasów średniowiecza,
                była małą rybacką wsią.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdynia1} width="66%" alt="Panorama Gdyni z Kamiennej Góry"/>
            </div>

            <h2>Miasto z morza i&nbsp;marzeń</h2>
            <p>
                Przemiana Gdyni w&nbsp;miasto spowodowana była skomplikowaną historią Polski po i&nbsp;wojnie światowej.
                Polska potrzebowała dostępu do portu na Morzu Bałtyckim. Jedyny taki port był w&nbsp;Gdańsku,
                ale nie należał on wówczas do kraju. To właśnie na gdyńskim wybrzeżu zdecydowano się wybudować nowy,
                duży i&nbsp;nowoczesny port morski, będący symbolem rozwijającego się państwa.
                Dlatego Gdynia została nazwana miastem z morza i&nbsp;marzeń. Do dziś port w&nbsp;Gdyni prężnie się rozwija.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdynia2} width="50%" alt="Fragment Portu w&nbsp;Gdyni"/>
            </div>

            <h2>Modernizm gdyński</h2>
            <p>
                Spacerując po Gdyni można zobaczyć budynki o charakterystycznej architekturze.
                w&nbsp;dużej mierze są to domy i&nbsp;obiekty wybudowane w&nbsp;latach dwudziestych i&nbsp;trzydziestych ubiegłego wieku.
                w&nbsp;budownictwie panował wówczas styl, który nazwany został gdyńskim modernizmem.
                Architektura ta wyróżnia się oszczędnym zdobnictwem, funkcjonalnością, zaokrąglonymi elementami
                i&nbsp;niedużą ilością kondygnacji.
                Często nawiązuje do budowy statków.
                Do dzisiaj wiele nowo wybudowanych obiektów w&nbsp;Gdyni kontynuuje tę tradycję architektoniczną.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdynia3} width="50%" alt="Modernizm gdyński wczoraj i&nbsp;dziś"/>
            </div>

            <h2>Atrakcje w&nbsp;Gdyni</h2>
            <p>
                Odpowiedź na pytanie co ciekawego zwiedzić w&nbsp;Gdyni da nam spacer po Molo Południowym.
                To jedyna tak bardzo dostępna dla wszystkich część gdyńskiego portu.
                Przechadzając się Skwerem Kościuszki można zwiedzić słynny okręt ORP Błyskawica, weterana II wojny światowej.
                Zaraz obok swoje stałe miejsce ma dawny statek szkolny, Dar Pomorza oraz jego następca, Dar Młodzieży.
                Po drugiej stronie Skweru Kościuszki można poznać mieszkańców mórz i&nbsp;oceanów, które hoduje Akwarium Gdyńskie.
                Tuż za nim rozciąga się piękna panorama na Orłowo.
                Wychodząc z Akwarium zobaczyć można marinę jachtową, a&nbsp;za nią rozciąga się miejska plaża.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={gdynia5} width="50%" alt="Akwarium gdyńskie"/>
                <img src={gdynia6} width="50%" alt="Plaża miejska w&nbsp;Gdyni"/>
            </div>
            <div style={{textAlign: "center"}}>
                <img src={gdynia4} width="50%" alt="ORP Błyskawica"/>
            </div>

            <h2>Muzea w&nbsp;Gdyni</h2>
            <p>
                Nieopodal miejskiej plaży, u stóp Kamiennej Góry, położony jest nowoczesny budynek,
                w&nbsp;którym sąsiadują ze sobą dwa muzea: Muzeum Marynarki Wojennej oraz Muzeum Miasta Gdyni.
                To jednak nie wszystkie ciekawe instytucje kultury w&nbsp;tym miejscu.
                Parę kroków dalej zobaczyć można największy teatr muzyczny w&nbsp;kraju, noszący imię Danuty Baduszkowej.
                Po obejrzeniu spektaklu w&nbsp;tym miejscu warto przejść się na Plac Grunwaldzki i&nbsp;podziwiać ciekawą
                architekturę Gdyńskiego Centrum Filmowego. Dobrym zakończeniem zwiedzania w&nbsp;Gdyni jest wizyta w&nbsp;Muzeum Emigracji.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdynia7} width="50%" alt="Muzeum Emigracji w&nbsp;Gdyni"/>
            </div>

            <h2>Kamienna Góra</h2>
            <p>
                Na koniec spaceru po centrum Gdyni najlepiej skorzystać z bezpłatnej atrakcji jaką jest kolejka na Kamienną Górę,
                aby z jej szczytu obejrzeć wyjątkową panoramę miasta, portu i&nbsp;morza.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={gdynia8} width="66%" alt="Kamienna Góra w&nbsp;Gdyni"/>
            </div>
        </Page>
    </>
}