import Page from "../../components/Page"
import other1 from "../../images/other/other_1.jpg"
import other2 from "../../images/other/other_2.jpg"
import other3 from "../../images/other/other_3.jpg"
import other4 from "../../images/other/other_4.jpg"
import other5 from "../../images/other/other_5.jpg"
import other6 from "../../images/other/other_6.jpg"

export default function Other(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>
            <h1>Pozostałe propozycje tras</h1>
            <h2>Trójmiasto "w pigułce"</h2>
            <p>
                Najlepsza opcja, aby w&nbsp;jeden dzień poznać najważniejsze i&nbsp;najpiękniejsze zakątki Trójmiasta:
                Drogę Królewską w&nbsp;Gdańsku, słynny “monciak” w&nbsp;Sopocie oraz Skwer Kościuszki i&nbsp;Molo Południowe w&nbsp;Gdyni.
            </p>

            <h2>Trójmiasto w&nbsp;dwa dni</h2>
            <p>
                Jeśli dysponują Państwo dwoma dniami to jest to doskonała okazja do poznania Trójmiasta nieco dokładniej.
                Pierwszy dzień to zwiedzanie Drogi Królewskiej w&nbsp;Gdańsku oraz jej okolicy a&nbsp;następnie przejazd do katedry
                w&nbsp;Oliwie lub na półwysep Westerplatte.
                Drugi dzień to zwiedzanie sopockiego “monciaka” zakończone spacerem po najsłynniejszym polskim molo.
                Po przejeździe do Gdyni zwiedzanie Skweru Kościuszki, Mola Południowego z atrakcjami do wyboru:
                wejście na ORP Błyskawica, statek Szkolny Dar Pomorza lub do Akwarium gdyńskiego.
            </p>

            <h2>Trójmiejskie plażowanie</h2>
            <p>
                Jednodniowa tematyczna wycieczka wokół tematu plażowania i&nbsp;odpoczynku: odwiedzimy trzy trójmiejskie mola:
                brzeźnieńskie w&nbsp;Gdańsku, najsłynniejsze sopockie oraz romantyczne molo w&nbsp;gdyńskim Orłowie.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={other1} width="50%" alt=""/>
            </div>

            <div style={{textAlign: "center"}}>
                <img src={other2} width="50%" alt=""/>
            </div>

            <h2>Oliwskie tajemnice</h2>
            <p>
                Kilkugodzinny spacer po przepięknej dzielnicy Gdańska.
                Punkty na trasie to wzgórze Pachołek, park oliwski oraz katedra oliwska, gdzie można wysłuchać koncertu organowego.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={other3} width="50%" alt=""/>
            </div>

            <h2>Trasa “bursztynowa”</h2>
            <p>
                Gdańsk jest często nazywany światową stolicą bursztynu i&nbsp;posiada miejsca, które to potwierdzają.
                Trasa zwiedzania to Długie Pobrzeże, ul. Mariacka, Muzeum Bursztynu oraz Bursztynowy Ołtarz w&nbsp;kościele św. Brygidy.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={other4} width="50%" alt=""/>
            </div>

            <h2>Wokół stoczni i&nbsp;Solidarności</h2>
            <p>
                Miejsce, w&nbsp;którym znajdują się tereny stoczniowe mają kilkusetletnią historię a&nbsp;dzisiaj przypominają
                przede wszystkim o wydarzeniach historycznych sprzed kilkudziesięciu lat.
                Spacer rozpoczyna się na Placu Solidarności przy Pomniku Poległych Stoczniowców.
                Trasa wiedzie obok Europejskiego Centrum Solidarności (zwiedzanie indywidualne), przez Salę BHP,
                ulicę Wałową do Muzeum II Wojny Światowej (zwiedzanie indywidualne).
            </p>

            <div style={{textAlign: "center"}}>
                <img src={other5} width="50%" alt=""/>
            </div>

            <h2>Śladami gdańskich Sądów Ostatecznych</h2>
            <p>
                Erudycyjny spacer skoncentrowany na malarskich przedstawieniach Sądu Ostatecznego w&nbsp;gdańskiej sztuce.
                Takich przykładów jest bardzo wiele i&nbsp;ukazują one różnorodność religijną w&nbsp;nowożytnym mieście.
                Przy okazji można zobaczyć ważne gdańskie zabytki: kościół Mariacki, Dwór Artusa oraz Ratusz Głównego Miasta.
                Zwieńczeniem spaceru jest wizyta w&nbsp;Muzeum Narodowym, gdzie znajduje się gdańska “perełka”: średniowieczny
                gdański tryptyk Hansa Memlinga przedstawiający Sąd Ostateczny.
            </p>

            <div style={{textAlign: "center"}}>
                <img src={other6} width="50%" alt=""/>
            </div>
        </Page>
    </>
}