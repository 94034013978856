import Page from "../../components/Page"
import oldtown1 from "../../images/oldtown/oldtown_1.jpg"

export default function OldTown(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>
            <h1>Stare Miasto w&nbsp;Gdańsku</h1>
            <p>
                Warto wybrać się na zwiedzanie Starego Miasta w&nbsp;Gdańsku i&nbsp;odkryć tajemnice tej mniej znanej dla turystów,
                ale równie ważnej dzielnicy miasta.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={oldtown1} width="66%" alt=""/>
            </div>

            <h2>Gdzie jest Stare a&nbsp;gdzie Główne Miasto w&nbsp;Gdańsku?</h2>
            <p>
                Sercem historycznego Gdańska są dwa obszary nazywane Głównym i&nbsp;Starym Miastem.
                Główne Miasto, często błędnie nazywane przez turystów gdańską “starówką”,
                to Droga Królewska oraz jej bliska okolica.
                Stare Miasto znajduje się w&nbsp;sąsiedztwie Głównego Miasta i&nbsp;jest najstarszym
                ośrodkiem miejskim, lokowanym w&nbsp;XIII wieku.
            </p>

            <h2>Najcenniejsze kościoły Starego Miasta</h2>
            <p>
                Na obszarze Starego Miasta w&nbsp;Gdańsku możemy zwiedzić dwa najstarsze, średniowieczne kościoły:
                parafialny św. Katarzyny oraz należący do zakonu dominikanów, pod wezwaniem św. Mikołaja.
                To najcenniejsze zabytki na Starym Mieście. w&nbsp;wieży kościoła św. Katarzyny znajduje się
                Muzeum Nauki Gdańskiej oraz carillion, instrument muzyczny składający się z kilkunastu dzwonów.
                Turyści regularnie mogą posłuchać gry na tym niezwykłym instrumencie. Kościół św. Mikołaja
                natomiast to jedyna niezniszczona przez działania II wojny światowej świątynia w&nbsp;mieście.
            </p>

            <h2>Kościół św. Brygidy</h2>
            <p>
                Wyjątkowym miejscem jest zbudowany pod koniec XV wieku kościół zakonny pod wezwaniem św. Brygidy.
                w&nbsp;latach osiemdziesiątych XX wieku znajdowali w&nbsp;nim schronienie prześladowani działacze NSZZ Solidarność, m.in. Lech Wałęsa.
                Wewnątrz świątyni znaleźć można wiele tablic pamiątkowych i&nbsp;rzeźb upamiętniających ofiary dla Ojczyzny, poległe podczas i&nbsp;po II wojnie światowej.
                w&nbsp;prezbiterium znajduje się prawdziwy skarb świątyni - Bursztynowy Ołtarz Ojczyzny, będący wotum wdzięczności za pontyfikat
                papieża Jana Pawła II oraz za wolność Polski. To największe dzieło na świecie wykonane z bursztynu!
            </p>

            <h2>Jan Heweliusz</h2>
            <p>
                Ten słynny gdańszczanin mieszkał i&nbsp;działał na Starym Mieście w&nbsp;XVII wieku.
                Był browarnikiem oraz znakomitym astronomem.
                Jego obserwatorium astronomiczne znajdowało się na dachach kilku kamienic.
                Został pochowany w&nbsp;kościele świętej Katarzyny, jednej z najstarszych świątyń w&nbsp;mieście.
                Sylwetka Jana Heweliusza upamiętniona została w&nbsp;postaci pomnika ustawionego naprzeciw ratusza Starego Miasta.
            </p>

            <h2>Wielki Młyn</h2>
            <p>
                To jeden z najokazalszych zabytków na Starym Mieście w&nbsp;Gdańsku.
                Wielki Młyn został zbudowany przez Krzyżaków już w&nbsp;XIV wieku a&nbsp;służył aż do 1945 roku.
                Był dużym osiągnięciem technicznym czasów średniowiecza.
                Dzisiaj znajduje się w&nbsp;nim siedziba Muzeum Bursztynu.
                Podziwiać w&nbsp;nim można szereg cennych eksponatów związanych ze “złotem Bałtyku”, jak nazywany jest bursztyn.
            </p>
        </Page>
    </>
}