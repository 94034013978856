import Page from "../../components/Page"
import westerplatte1 from "../../images/westerplatte/westerplatte_1.jpg"
import westerplatte2 from "../../images/westerplatte/westerplatte_2.jpg"
import westerplatte3 from "../../images/westerplatte/westerplatte_3.jpg"
import westerplatte4 from "../../images/westerplatte/westerplatte_4.jpg"
import westerplatte5 from "../../images/westerplatte/westerplatte_5.jpg"

export default function Westerplatte(props) {
    return <>
        <Page style={{textAlign: "justify", padding: "20px"}}>
            <h1>Westerplatte</h1>
            <p>
                Zwiedzanie tego półwyspu to obowiązkowy punkt na mapie Gdańska jeśli chcemy dowiedzieć się jak rozpoczęła się II wojna światowa.
                Spacer z przewodnikiem pomoże zrozumieć jak wyglądała obrona Westerplatte, która miała miejsce we wrześniu 1939 roku.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={westerplatte1} width="66%" alt="Powojenny napis „nigdy więcej wojny”"/>
            </div>

            <h2>Droga na Westerplatte</h2>
            <p>
                Na półwysep można się dostać drogą lądową lub wodną.
                w&nbsp;sezonie letnim z Długiego Pobrzeża przy Zielonym Moście w&nbsp;Gdańsku co godzinę wypływają jednostki pasażerskie,
                które płyną na Westerplatte kanałem portowym.
                Jest to doskonała okazja, żeby obejrzeć port Gdański.
                Można również dojechać na miejsce samochodem i&nbsp;wysiąść na dużym parkingu.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={westerplatte2} width="50%" alt="Przystań żeglugi na Westerplatte"/>
            </div>

            <h2>Historia półwyspu Westerplatte</h2>
            <p>
                Westerplatte stało się półwyspem dopiero w&nbsp;XIX wieku, wcześniej była to wyspa.
                Miejsce to kojarzy się przede wszystkim z wybuchem II wojny światowej ale jego historia militarna jest znacznie dłuższa i&nbsp;sięga XVIII wieku.
                w&nbsp;drugiej połowie XIX wieku i&nbsp;na początku wieku XX był to również znany kurort, miejsce wypoczynku i&nbsp;leczenia różnych chorób.
                Po zakończeniu i&nbsp;wojny światowej utworzono na tym terenie Wojskową Składnicę Tranzytową.
            </p>

            <h2>Wojskowa Składnica Tranzytowa</h2>
            <p>
                Po traktacie wersalskim w&nbsp;1920 roku, półwysep Westerplatte został oddany niepodległej Polsce i&nbsp;utworzono na nim
                Wojskową Składnicę Tranzytową, służącą do przeładunku broni.
                Pierwsi polscy żołnierze przybyli tu w&nbsp;styczniu 1926 roku.
                Załoga zmieniała się co pół roku.
                Składnica funkcjonowała aż do wybuchu II wojny światowej, a&nbsp;jej załoga broniła się od 1 do 7 września 1939 roku.
                w&nbsp;trakcie trwania wojny jej teren należał do Rzeszy, a&nbsp;po zakończeniu działań wojennych powrócił do Polski.
                Dziś jest to miejsce pamięci po tamtych wydarzeniach.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={westerplatte3} width="50%" alt="Nowe koszary na Westerplatte"/>
            </div>

            <h2>Wartownia nr 1</h2>
            <p>
                Pozostałością z czasów istnienia Składnicy jest Wartownia nr 1, jedna z pięciu tego typu budowli, powstałych, aby bronić
                Westerplatte w&nbsp;razie ataku nieprzyjaciela.
                Dziś jest to oddział Muzeum Gdańska, otwarty w&nbsp;sezonie letnim.
                Na ścianach tego niemego świadka historii zobaczyć można wiele śladów od wrogich pocisków.
            </p>

            <h2>Jan Paweł II</h2>
            <p>
                Papież Polak  Jan Paweł II, odwiedził Westerplatte 12 czerwca 1987 podczas pielgrzymki do swojego kraju.
                Spotkał się tam z młodzieżą, u stóp Pomnika Obrońców Wybrzeża.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={westerplatte4} width="50%" alt="Pomnik upamiętniający wizytę Jana Pawła II na Westerplatte"/>
            </div>

            <h2>Pomnik Obrońców Wybrzeża</h2>
            <p>
                Punktem kulminacyjnym spaceru po Westerplatte jest Pomnik Obrońców Wybrzeża
                ustawiony na kopcu usypanym z ziemi wydobytej podczas poszerzania kanału portowego.
                Obelisk pochodzi z 1966 roku i&nbsp;jest pozostałością po trudnym okresie komunistycznym.
                Spod Pomnika rozciąga się piękna panorama Gdańska, a&nbsp;także widok na gdański port.
            </p>
            <div style={{textAlign: "center"}}>
                <img src={westerplatte5} width="66%" alt="Pomnik Obrońców Wybrzeża na Westerplatte"/>
            </div>
        </Page>
    </>
}